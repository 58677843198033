import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import Error404 from '@/components/svg/404';
import { colors } from '../styles/theme';

export default function Custom404(): ReactElement {
  const router = useRouter();

  return (
    <React.Fragment>
      <style jsx>
        {`
          .background {
            width:100%;
            height:100%;
            position:absolute;
            background: url('/images/404-background.png') no-repeat center ${colors.primary};
            background-size: cover;
          }
          .text {
            font-weight: bold;
            font-size: 1.9375rem;
            color: rgba(255, 255, 255, 0.5);
          }
          .back {
            background-color: white;
            width: 114px;
            height: 41px;
            border-radius: 10px;
            padding: 4px 32px;
            margin-top: 2.125rem;
            color: ${colors.primary};
            font-weight: bold;
          }
        `}
      </style>
      <div className="flex flex-col items-center justify-center background">
        <Error404 width={578} height={218} />
        <p className="text text-center">Página não encontrada</p>
        <button type="button" className="flex flex-row items-center align-items back" onClick={() => router.back()}>Voltar</button>
      </div>
    </React.Fragment>
  );
}
